<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div v-if="input.projectDivCd == 3" class="title-txt">공급기업 지정 프로젝트 &gt; 전체 프로젝트<br/>
						<p><span class="prj_ct">프로젝트 담당자께서 등록한 전체 프로젝트 리스트</span></p>
					</div>
					<div v-else class="title-txt">일반 프로젝트 &gt; 전체 프로젝트<br/>
						<p><span class="prj_ct">프로젝트 담당자께서 등록한 전체 프로젝트 리스트</span></p>
					</div>
				</div>
				<div class="contents-body prj">
					<div class="filter">
						<div class="search_box">
							<SelectComp type="select" class="prj_name" list="1:프로젝트명,2:담당자명" v-model="input.searchGubun"/>						
							<InputComp type="text" classNm="search_box int" :placeholder="input.searchGubun == '1' ? '프로젝트명' : '담당자명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
							<div class="search_btn" @click="getList('1')"><img class="search" src="/images/search_btn.png" alt="검색버튼"/></div>
						</div>
						<div class="filter_box prj_list">
							<ul>
								<li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">등록일 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('2')">참여인재 많은 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '3' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('3')">참여인재 적은 순</p></li>
							</ul>
							<div class="state">
								프로젝트 상태
								<label for="00"><input type="checkbox" name="state" id="00" v-model="input.projectStatus" value="1" @change="getList('1')">수행중</label>
								<label for="01"><input type="checkbox" name="state" id="01" v-model="input.projectStatus" value="2" @change="getList('1')">수행예정</label>
								<label for="02"><input type="checkbox" name="state" id="02" v-model="input.projectStatus" value="9" @change="getList('1')">완료</label>
							</div>
						</div>
					</div>
					<!-- 전체프로젝트 리스트 -->
					<div class="Board type3">
						<table class="Board_type3 demand">
							<colgroup>
								<col width="5%">
								<col width="43%">
								<col width="17%">
								<col width="8%">
								<col width="12%">
								<col width="15%">
							</colgroup>
							<thead>
								<tr>
									<th>NO</th>
									<th>프로젝트명</th>
									<th>개발기간</th>
									<th>참여인재</th>
									<th>프로젝트 상태</th>
									<th>담당자</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(project, index) in projectList" :key="project.projectSeq">
									<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
									<td class="project"><p>{{project.projectNm}}</p><div class="btn dt" @click="clickProjectInfo(project.projectSeq)">자세히보기 &#9654;</div></td>
									<td class="score">{{project.projectStartEndYyyymmdd}}</td>
									<td class="score">{{project.mberCnt}}명</td>
									<!-- <td class="score">
										참여:{{project.mberCnt}}명<br/>
										지원:{{project.appMberCnt}}명<br/>
										미평가:{{project.unEvalCount}}명<br/>
									</td> -->
									<td class="score">
										<span v-if="project.projectStatus == 1">수행중</span>
										<span v-else-if="project.projectStatus == 2">수행예정</span>
										<span v-else-if="project.projectStatus == 9">완료</span>
										<span v-else-if="project.projectStatus == 4">제안단계</span>
										<span v-else-if="project.projectStatus == 8">미승인</span>
									</td>
									<td class="score">
										<span class="btn_cursor" @click="mberPopup(project.corpMgrMberSeq)">{{project.corpMgrMberNm != null ? project.corpMgrMberNm + ' &#9654;' : ''}}</span>
										<div class="btn change" @click="clickChangeCorpMgrMberNm(project.projectSeq)">변경 <div class="triangle"></div></div></td>
								</tr>	
								<!-- 등록된 프로젝트가 없을 경우 -->
								<tr v-if="pageInfo.totalRecordCount == 0">
									<td colspan="6" class="none">등록된 프로젝트가 없습니다!</td>
								</tr>
							</tbody>
						</table>
					</div>
					
					<!--페이징 컴포넌트 -->
					<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
					<!--// 페이징 컴포넌트 -->
					
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';

export default {

	components : {
		PagingComp
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				orderGubun : '1',	// 1:등록일 순, 2:참여인재 많은 순, 3:참여인재 적은 순
				searchGubun : '1',	// 1:프로젝트명, 2:담당자명
				searchKeyword : '',
				projectStatus : []
			}, // 검색조건		
			projectList : [],
			pageInfo : {},
			
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		this.input.projectDivCd = this.$route.name.substr(5,1) == '1' ? '3' : '1';
	},
	mounted() {
		// console.log('mounted');
		if(this.$route.params != undefined && this.$route.params.pageIndex != undefined) {
			this.input = this.$route.params;
		}

		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList(div) {
			if(div) this.input.pageIndex = 1;
			
			this.$.httpPost('/api/prj/dco/gmg/getTotalProjectList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectList = res.data.list;
					this.pageInfo = res.data.pageInfo;
					
					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList();
		},		

		clickProjectInfo(projectSeq) {
			// this.$router.push({name : 'PRJ201M02', params : {projectSeq : projectSeq, caller : { name : 'PRJ201M01', params :this.input}}});
			this.$router.push({name : 'PRJ201M02', query : {projectSeq : projectSeq}, params : {caller : { name : 'PRJ201M01', params :this.input}}});
		},

		clickChangeCorpMgrMberNm(projectSeq) {
			var param = {
				projectSeq : projectSeq
			};
			this.$.popup('/dco/gmg/prj/PRJ201P01', param, {width:0, height:0})
				.then(() => {
					this.getList();
				})
				// .catch(err => {})
				;
		},

		mberPopup(seq) {
			var div = 'dco';
			this.$.popup('/dco/gmg/myp/MYP201P01', { seq, div });
		}

	},
};
</script>
